<template>
    <div class="tongji">
        <div class="tabs">
            <a-menu v-model="type" mode="horizontal" @click="changeDate">
                <a-menu-item key="all">全部</a-menu-item>
                <a-menu-item key="year">本年</a-menu-item>
                <a-menu-item key="month">本月</a-menu-item>
                <a-menu-item key="day">今日</a-menu-item>
            </a-menu>
            <div class="refresh"><a @click="refresh">刷 新 ({{time}}s)</a></div>
        </div>
        <div class="content">
            <a-row :gutter="16">
                <a-col :span="6">
                    <a-statistic title="用户数" :value="info.user_total"></a-statistic>
                </a-col>
                <a-col :span="6">
                    <a-statistic title="网点数" :value="info.branch_total"></a-statistic>
                </a-col>
                <a-col :span="6">
                    <a-statistic title="询价总单数" :value="info.order_count"></a-statistic>
                </a-col>
                <a-col :span="6">
                    <a-statistic title="询价总金额" :value="info.order_total"></a-statistic>
                </a-col>
                <a-col :span="6">
                    <a-statistic title="成交总单数" :value="info.comp_count"></a-statistic>
                </a-col>
                <a-col :span="6">
                    <a-statistic title="成交总金额" :value="info.comp_total"></a-statistic>
                </a-col>
                <a-col :span="6"></a-col>
                <a-col :span="6"></a-col>
            </a-row>
            <a-divider orientation="left" style="margin-top:50px;">
                待处理信息
            </a-divider>
            <a-row :gutter="16">
                <a-col :span="6">
                    <a @click="goto_account"><a-statistic title="待处理收款信息" :value="pendding.account_count"></a-statistic></a>
                </a-col>
                <a-col :span="6">
                    <a @click="goto_branch"><a-statistic title="待处理网点地址信息" :value="pendding.branch_count"></a-statistic></a>
                </a-col>
                <a-col :span="6">
                    <a @click="goto_order"><a-statistic title="待处理询价信息" :value="pendding.order_count"></a-statistic></a>
                </a-col>
            </a-row>
        </div>
    </div>
</template>

<script>
    import Func from '../common/func'
    var time1=null;
    export default {
        name: 'Users',
        data(){
            return {
                user_total:0,
                reserves_total:0,
                type:'day',
                time:30,
                info:{
                    user_total:1,
                    branch_total:1,
                    order_count:1,
                    order_total:20000,
                    comp_count:0,
                    comp_total:0
                },
                pendding:{
                    user_count:0,
                    account_count:0,
                    branch_count:0,
                    order_count:0
                }
            }
        },
        mounted() {
            this.get_data('all');
            this.get_pendding();
            this.times();
        },
        beforeDestroy(){
            clearInterval(time1);
        },
        methods: {
            changeDate(e){
                this.get_data(e.key);
            },
            refresh(){
                this.get_data(this.type);
                this.get_pendding();
                this.times();
            },
            times(){
                this.time=30;
                clearInterval(time1);
                time1=setInterval(()=>{
                    this.time=this.time-1;
                    if(this.time<=0){
                        this.time=30;
                        this.refresh();
                    }
                },1000);
            },
            get_data(type) {
                Func.url_get({
                    action:'index.tongji',
                    type
                },(res) => {
                    this.info=res.info;
                });
            },
            get_pendding() {
                Func.url_get({
                    action:'index.pendding'
                },(res) => {
                    this.pendding=res.info;
                });
            },
            goto_account(){
                this.$router.push({
                    path: "/account",
                    query: {
                        check: 2
                    },
                });
            },
            goto_branch(){
                this.$router.push({
                    path: "/branch",
                    query: {
                        check: 0
                    },
                });
            },
            goto_order(){
                this.$router.push({
                    path: "/order",
                    query: {
                        status: -2
                    },
                });
            }
        }
    }
</script>
<style lang="scss">
.tongji{
    font-size:20px;
    width: 100%;
    .ant-statistic-content-value-int,.ant-statistic-content-value-decimal{
        font-size:35px;
        font-weight: 650;
        color:red;
    }
    .content{
        margin:20px 40px 0 40px;
        .ant-col{
            margin-top:40px;
            text-align: center;
        }
    }
    .tabs{
        position: relative;
        .refresh{
            position: absolute;
            right:10px;
            bottom:10px;
            font-size:16px;
        }
    }
}
</style>