<template>
  <div id="home">
    <div id="header">
      <div class="bg">
        <div class="bg_left">
        </div>
        <div class="bg_right">
          <img src="../assets/image/bg.png" />
        </div>
      </div>
      <div class="logo_menu">
        <div class="logo">
          <img src="../assets/image/logo1.png" />
        </div>
      </div>
    </div>
    <div id="main">
      <div id="login">
        <div class="main">
          <div class="content">
            <div class="form">
              <div class="row">
                <!-- <div class="label"> -->
                  <!-- <i class="iconfont icon-denglu-shoujihao"></i> -->
                <!-- </div> -->
                <div class="text">
                  <input type="text" id="title" placeholder="请输入账号" v-model="username" />
                </div>
              </div>
              <div class="row">
                <!-- <div class="label"> -->
                  <!-- <i class="iconfont icon-yanzhengma"></i> -->
                <!-- </div> -->
                <div class="text">
                  <input type="password" id="title" v-model="password" placeholder="请输入密码" />
                </div>
              </div>
            </div>
            <div class="btn_area">
              <a href="javascript:void(0);" @click="login_data">确认</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="footer">
      ©2022 中商锂新能源 版权所有
    </div>
  </div>
</template>
<script>
var dom=1;
export default {
  data () {
    return {
      username:'',
      password:'',
      time:0,
    }
  },
  watch:{
    
  },
  created(){},
  methods: {
    login_data(){
      this.$Func.url_login({
        action:'index.login',
        username:this.username,
        password:this.password
      },(res)=>{
        if(res.error=='1'){
          this.$message.error(res.info);
        }else{
          var result=res.info;

          sessionStorage.setItem('user_info',JSON.stringify(result));
          this.$store.commit('setInfo', result);
          this.$router.push('/home');
        }
      })
    },
    goto_replace(url){
      this.$router.push('/'+url+'?'+dom);
      dom++;
    },
    goto_page(url){
      this.$router.push(url);
    }
  }
}
</script>
<style lang="scss" scoped="scoped">
#home{
  background: linear-gradient(to bottom right, #07be61, #0d5e95);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
}
  #header{
    z-index: 9999;
    .bg{
      position: fixed;
      display: flex;
      justify-content:space-between;
      bottom:0;
      right:0;
      .bg_left{
        margin-left:-10px;
        img{
          height: 208px;
        }
      }
      .bg_right{
        img{
        }
      }
    }
    .logo_menu{
      position: relative;
      display: flex;
      justify-content:center;
      align-items:flex-end;
      top:80px;
      .logo{
        margin-top:150px;
        img{
          height: 80px;
        }
      }
      .menu{
        display: flex;
        .item{
          padding:0 40px 5px 40px;
          color:#333;
          a{
            color:#333;
            font-size:23px;
          }
          .sub_menu{
            position: absolute;
            left:30px;
            background: #f6f0f1;
            border-radius: 10px;
            padding: 10px 0;
            a{
              font-size: 14px;
              text-align: center;
              padding:8px 20px;
              display: block;
            }
            a.active,a:hover{
              background: #ee7979;
              border-radius: 6px;
              color:#fff;
            }
          }
        }
        .active{
          color:red;
          a{
            color:red;
          }
        }
      }
    }
  }
  #main{
    text-align: center;
    display: flex;
    justify-content:center;
    align-items:center;
    height: 500px;
    #login{
      width:500px;
      margin-top:-80px;
      .header{
        height:80px;
        background-size:100%;
        font-size:23px;
        text-align: center;
        padding-top:20px;
        color:#fff;
        font-weight: 500;
        .icon_left{
          position:absolute;
          left:25px;
          top:24px;
          img{
            width:12px;
          }
        }
      }
      .note{
        margin-top:20px;
        text-align:center;
        color:#efefef;
        font-size:14px;
        a{
          color:#ffd7cf;
        }
      }
      .main{
        margin:0 20px;
        background:#fff;
        border-radius:15px;
        z-index: 99;
        box-shadow: 0px 3px 10px #ddd;
        padding: 0px 0px 5px 0px;
        .title{
          font-size:12px;
          text-align:center;
          line-height:30px;
          margin:0 10px;
          border-bottom:1px solid #ffefef;
          color:red;
          display:flex;
          .icon-speaker{
            color:red;
            font-size:12px;
          }
          .notify{
            width:auto;
            white-space: nowrap;
            overflow:hidden;
            padding-left:5px;
          }
        }
        .content{
          padding:20px 0;
          .row{
            display: flex;
            align-items:center;
            margin-bottom:10px;
            margin:0 25px;
            padding:5px 0px;
            border-bottom:1px solid #ececec;
            .label{
              font-size:12px;
              color:#545454;
              border:1px solid #fe3b27;
              border-radius:3px;
              padding:3px;
              i{
                color:#fe3b27;
              }
            }
            .text{
              flex:10;
              width: auto;
              margin-left:10px;
              input{
                width: 100%;
                height: 30px;
                border:0;
              }
              .get_code_area{
                margin-top:-25px;
                text-align:right;
                right:45px;
                .get_code{
                  padding:3px 8px;
                  border:1px solid red;
                  border-radius:20px;
                  color:red;
                  font-size: 13px;
                }
              }
            }
          }
          .btn_area{
            display: flex;
            justify-content: center;
            margin:30px 20px;
            a{
              display: block;
              line-height:2.2em;
              border-radius: 10px;
              background-image: linear-gradient(#5fff85, #153fde);
              font-size:18px;
              font-weight:450;
              width:100%;
              color:#fff;
              text-align: center;
            }
          }
        }
      }
    }
    
  }
  
  #footer{
    padding-top:100px;
    bottom: 10px;
    width: 100%;
    text-align: center;
    margin-top:20px;
    color:#eee;
  }
</style>
